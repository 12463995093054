import logo from './logo.svg';
import './App.css';
import { useEffect, useState } from 'react';
const priority = require('priority-web-sdk');
const assert = require('assert');
let isRanOnce = false;
function AppInvoice() {
  const [isShowDoc, setIsShowDoc] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(null);

  async function webSDK(username, password) {

    /* let config={
      url:"https://s.priority-connect.online/webui",
      tabulaini:"https://www.eshbelsaas.com/ui/odata/priority/tabmob.ini",
      language: 3,
      profile: {
                    company: 'test',
                },
      appname:'demo',
      username: username,
      password: password,
      devicename:'', 
      "X-App-Id": 'APP081',
      "X-App-Key": '3B239209D70944D6B3DA425717FE034F'
    }; */
    const configuration = {
      username: username,
      password: password,
      url: "https://www.eshbelsaas.com/ui",
      tabulaini: "tabmob.ini",
      language: 1,
      profile: {
        company: "tp"
      }
    }
    try {
      await priority.login(configuration)
      console.log('Your are in!! Enjoy!')
    }
    catch (reason) {
      console.error(reason.message)
    }
  };
  /*  async function saveFile(dataURI) {
     var regex = /^data:.+\/(.+);base64,(.*)$/;
     var matches = dataURI.match(regex);
     var ext = matches[1];
     var data = matches[2];
     var buffer = Buffer.from(data, 'base64');
     return fs.writeFile('data.' + ext, buffer);
   } */// SO23062806
   /* "username": "newusername",
   "password": "11111111",
   "url": "https://www.eshbelsaas.co.il/pr16",
   "company": "nevet",
   "tabulaini": "tabp5049.ini", */
  // http://localhost:3000/invoice/?user=newusername&pass=11111111%2B&url=https://www.eshbelsaas.co.il/pr16&tabulaini=tabp5049.ini&company=nevet&procedure=WWWSHOWAIV&format=-3&sorting=לפי מספר החשבונית&docId=IN224000004
  function getQueryParam(paramName) {
    const urlParams = new URLSearchParams(new URL(window.location.href).search);

    return urlParams.get(paramName);
  }
  async function showInvoice() {
    const procedureName = getQueryParam("procedure");
    const invoiceID = getQueryParam("docId");
    const sorting = getQueryParam("sorting");
    const format = getQueryParam("format");
    const autoConfig = {
      username: getQueryParam("user"),
      password: getQueryParam("pass"),
      url: getQueryParam("url"),
      appid: "APP080",
      appkey: "AB53E969725C4F53B9BE12A0521EA048",
      tabulaini: getQueryParam("tabulaini"),
      language: 1,
      profile: {
        company: getQueryParam("company")
      }
    }
    // OV225000002 WWWSHOWEIV
    // PI19000003 WWWSHOWRIV

    /* const procedureName = "WWWSHOWRIV";
    const sorting = "לפי מספר החשבונית";
    const format = "-3";
    const autoConfig = {
      username: "tp97",
      password: "Tp123+",
      url: "https://www.eshbelsaas.com/ui",
      tabulaini: "tabmob.ini",
      language: 1,
      profile: {
        company: "tp"
      }
    }
    console.log("our getCongif", autoConfig);
    const invoiceID = "PI19000003"; */

    var errorMessage = "###error### ";
    try {
      await priority.login(autoConfig);
    } catch (error) {
      console.log('Something awful happened:', error);
      setIsShowDoc(true);
      errorMessage += error.code + " " + error.message
      setPdfUrl(errorMessage);
    }

    try {
      /* Start the procedure */
      /* Start the procedure */
      let procStepResult = await priority.procStart(procedureName, 'P', null);

      /* Populate input dialog */
      assert(procStepResult.type == 'inputFields');
      procStepResult = await procStepResult.proc.inputFields(1, {
        EditFields: [
          { field: 1, value: invoiceID, op: 0, value2: '', op2: '' },
          { field: 2, value: sorting, op: 0, value2: '', op2: '' }
        ]
      });
      /* let procStepResult = await priority.procStart('WWWSHOWTIV', 'P');    
      
      assert(procStepResult.type == 'inputFields');
      procStepResult = await procStepResult.proc.inputFields(1, {EditFields:[
        {field: 1, value: "RC239000002", op: 0, value2: '', op2: ''},
        {field: 2, value: 'לפי מספר הקבלה', op: 0, value2: '', op2: ''}
      ]}); */
      console.log("start procStepResult", procStepResult)
      /* Special 'client' step - just ignore and continue the flow */
      assert(procStepResult.type == 'client');
      procStepResult = await procStepResult.proc.clientContinue();
      console.log("doc search result", JSON.stringify(procStepResult.formats));
      /* Select template */
      assert(procStepResult.type == 'documentOptions');
      //console.log(procStepResult.formats); // Shows the various templates, need to select one for the next step.
      procStepResult = await procStepResult.proc.documentOptions(1 /* OK */, format /* selectedFormat */, { pdf: 1, word: 0, mode: 'display' } /* options */);

      /* Do something with the document */
      //assert();
      //assert(procStepResult.Urls[0].action == 'display');
      console.log("Process Doc Step result", procStepResult);
      console.log(procStepResult.Urls[0].url);  /* Will be deprecated in future versions and replaced with dataURL */
      if (procStepResult.type == 'displayUrl') {
        setIsShowDoc(true);
        setPdfUrl(procStepResult.Urls[0].url);
      }
      /* Finish the proc */
      procStepResult = await procStepResult.proc.continueProc();
      assert(procStepResult.type == 'end');
    } catch (err) {
      console.log('Something awful happened:', err);
      console.dir(err);
      setIsShowDoc(true);
      errorMessage += err.code + " " + err.message
      setPdfUrl(errorMessage);
    }
  }
  useEffect(() => {
    if (!isShowDoc && !isRanOnce) {
      isRanOnce = true;
      showInvoice();
    }
  }, []);

  return (
    <>
      {!isShowDoc && (<div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          {/* <p style={{ cursor: "pointer" }} onClick={() => webSDK("master", "AdiO2024+")}>
            Login.
          </p>
            <p style={{ cursor: "pointer" }} onClick={() => showAIV('IN224000004')}>
          Show doc
        </p> */}
        </header>
      </div>)}
      {isShowDoc && (<div className="App">
        <div id="pdf-link">{pdfUrl}</div>
      </div>)}
    </>

  );
}

export default AppInvoice;
