import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AppOrder from './App-order';
import AppInvoice from './App-invoice-works';
import AppSagi from './App-invoice-signed-advanced';
let isRunOnce = false;
function App() {
  return (
    <Router>
      <div>
        {/* You can add a navigation bar or links here */}
      </div>
      <Routes>
        <Route path="/signed" element={<AppSagi />} />
        <Route path="/invoice" element={<AppInvoice />} />
        <Route path="/order" element={<AppOrder />} />
        {/* Add a default route if you like, for example a home page */}
        {/* <Route path="/" element={<HomePage />} /> */}
      </Routes>
    </Router>
  );
}

export default App;
