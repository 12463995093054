import logo from './logo.svg';
import './App.css';
import { useEffect, useState } from 'react';
const priority = require('priority-web-sdk');
const assert = require('assert');

function AppSagi() {
  const [isShowDoc, setIsShowDoc] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(null);

  async function webSDK(username, password) {

    /* let config={
      url:"https://s.priority-connect.online/webui",
      tabulaini:"https://www.eshbelsaas.com/ui/odata/priority/tabmob.ini",
      language: 3,
      profile: {
                    company: 'test',
                },
      appname:'demo',
      username: username,
      password: password,
      devicename:'', 
      "X-App-Id": 'APP081',
      "X-App-Key": '3B239209D70944D6B3DA425717FE034F'
    }; */
    const configuration = {
      username: username,
      password: password,
      url: "https://www.eshbelsaas.com/ui",
      tabulaini: "tabmob.ini",
      language: 1,
      profile: {
        company: "tp"
      }
    }
    try {
      await priority.login(configuration)
      console.log('Your are in!! Enjoy!')
    }
    catch (reason) {
      console.error(reason.message)
    }
  };
  /*  async function saveFile(dataURI) {
     var regex = /^data:.+\/(.+);base64,(.*)$/;
     var matches = dataURI.match(regex);
     var ext = matches[1];
     var data = matches[2];
     var buffer = Buffer.from(data, 'base64');
     return fs.writeFile('data.' + ext, buffer);
   } */// SO23062806
  // http://localhost:3000/signed/?user=tp97&pass=Tp123%2B&url=https%3A%2F%2Fwww.eshbelsaas.com%2Fui&tabulaini=tabmob.ini&company=tp&procedure=WWWSHOWAIV&format=-3&sorting=לפי מספר החשבונית&docId=IN224000004
  function getQueryParam(paramName) {
    const urlParams = new URLSearchParams(new URL(window.location.href).search);
    
    return urlParams.get(paramName);
  }
  async function showAIV() {
   /*  const procedureName = getQueryParam("procedure");
    const sorting = getQueryParam("sorting");
    const format = getQueryParam("format");
    const autoConfig = {
      username: getQueryParam("user"),
      password: getQueryParam("pass"),
      url: getQueryParam("url"),
      tabulaini: getQueryParam("tabulaini"),
      language: 1,
      profile: {
        company: getQueryParam("company")
      }
    } */
    // OV225000002 WWWSHOWEIV
    // PI19000003 WWWSHOWRIV
    const procedureName = "WWWSHOWAIV";
    const sorting = "לפי מספר החשבונית";
    const format = "-3";
    const autoConfig = {
      username: "API",
      password: "API100",
      url: "https://elite.wee.co.il",
      tabulaini: "tabula.ini",
      language: 1,
      profile: {
        company: "elite"
      }
    }
    // https://elite.wee.co.il/odata/Priority/tabula.ini/elite/
    console.log("our getCongif", autoConfig);
    const invoiceID = "IN244000001";

    try {
      await priority.login(autoConfig);
    } catch (error) {
      console.log('Something awful happened:', error);
      setIsShowDoc(true);
      setPdfUrl("loginFail");
    }

    console.log("Form start");
    let mainFormHandler = await priority.formStart("AINVOICES", onError1(), onSucess(), '', 1);

    let filter = {
      or: 0,
      ignorecase:1,
      QueryValues: [{
      field : "IVNUM",
      fromval : "IN244000001",
      op : "=",
      sort : 0,
      isdesc : 0
      }]
      };

    await mainFormHandler.setSearchFilter(filter);
    await mainFormHandler.getRows(1);
    let subform = await mainFormHandler.startSubForm('EXTFILES', onError2,null);

    let filter_subform = {
      or: 0,
      ignorecase:1,
      QueryValues: [{
      field : "EXTFILENUM",
      fromval : "1",
      op : "=",
      sort : 0,
      isdesc : 0
      }]
      };

    await subform.setSearchFilter(filter_subform);
    let extfiles = await subform.getRows(1);
    let filename = extfiles.EXTFILES[1].EXTFILENAME;
    console.log("Filename:", filename);
    const filedata = await subform.getFileDataUri(filename);
    console.log("BASE64:", filedata);
    if (filename) {
      setIsShowDoc(true);
      setPdfUrl(filedata);
    }
  }
  
  function onError1(error) {
    console.log("our error 1", error);
    setPdfUrl("error " + error.code + " " + error.message);
  }
  function onError2(error) {
    console.log("our error 2", error);
    setPdfUrl("error " + error.code + " " + error.message);
  }
  function onSucess(error) {
    console.log("on success 1", error);
    setPdfUrl("error " + error.code + " " + error.message);
  }
  useEffect(() => {
    if (!isShowDoc) {
      showAIV();
    }
  },[]);

  return (
    <>
      {!isShowDoc && (<div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
         {/* <p style={{ cursor: "pointer" }} onClick={() => webSDK("master", "AdiO2024+")}>
            Login.
          </p>
            <p style={{ cursor: "pointer" }} onClick={() => showAIV('IN224000004')}>
          Show doc
        </p> */}
        </header>
      </div>)}
      {isShowDoc && (<div className="App">
        <div id="pdf-link">{pdfUrl}</div>
      </div>)}
    </>

  );
}

export default AppSagi;
