import logo from './logo.svg';
import './App.css';
import { useEffect, useState } from 'react';
const priority = require('priority-web-sdk');
const assert = require('assert');

function AppOrder() {
  const [isShowDoc, setIsShowDoc] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(null);

  /*  async function saveFile(dataURI) {
     var regex = /^data:.+\/(.+);base64,(.*)$/;
     var matches = dataURI.match(regex);
     var ext = matches[1];
     var data = matches[2];
     var buffer = Buffer.from(data, 'base64');
     return fs.writeFile('data.' + ext, buffer);
   } */// SO23062806
    const sorting = "לפי מספר החשבונית";
    // http://localhost:3000/order/?user=tp97&pass=Tp123%2B&url=https%3A%2F%2Fwww.eshbelsaas.com%2Fui&tabulaini=tabmob.ini&company=tp&procedure=WWWSHOWCPROF&format=-3&sorting=לפי מספר ההצעה&docId=PQ23000001
  // http://localhost:3000/order/?user=tp97&pass=Tp123%2B&url=https%3A%2F%2Fwww.eshbelsaas.com%2Fui&tabulaini=tabmob.ini&company=tp&procedure=WWWSHOWORDER&format=-3&sorting=לפי מספר הזמנה&docId=SO24000089
    function getQueryParam(paramName) {
    const urlParams = new URLSearchParams(new URL(window.location.href).search);
    
    return urlParams.get(paramName);
  }
  async function showAIV() {
    const procedureName = getQueryParam("procedure");
    const sorting = getQueryParam("sorting");
    const format = getQueryParam("format");
    const invoiceID = getQueryParam("docId"); 
    const autoConfig = {
      username: getQueryParam("user"),
      password: getQueryParam("pass"),
      url: getQueryParam("url"),
      appid: "APP080",
      appkey: "AB53E969725C4F53B9BE12A0521EA048",
      tabulaini: getQueryParam("tabulaini"),
      language: 1,
      profile: {
        company: getQueryParam("company")
      }
    }/*  */
    // OV225000002 WWWSHOWEIV
    // PI19000003 WWWSHOWRIV
    // const sorting = "לפי מספר הזמנה";
   /*  const procedureName = "WWWSHOWCPROF";
    const sorting = "לפי מספר החשבונית";
    const format = "-3";
    const autoConfig = {
      username: "tp97",
      password: "Tp123+",
      url: "https://www.eshbelsaas.com/ui",
      tabulaini: "tabmob.ini",
      language: 1,
      profile: {
        company: "tp"
      }
    }
    console.log("our getCongif", autoConfig);*/
    
    var errorMessage = "###error### ";
    try {
      await priority.login(autoConfig);
    } catch (error) {
      console.log('Something awful happened:', error);
      setIsShowDoc(true);
      errorMessage += error.code + " " + error.message
      setPdfUrl(errorMessage);
    }

    try {
      /* Start the procedure */
     /* Start the procedure */
    let procStepResult = await priority.procStart(procedureName, 'P', null);
    
    /* Populate input dialog */
    assert(procStepResult.type == 'inputFields');
    procStepResult = await procStepResult.proc.inputOptions(1 , 1);
    var data = {EditFields: [
        {field: 1, op: 0, value: invoiceID},
        //{field: 2, op: 0, value: 'לפי מספר הזמנה'}
        {field: 2, op: 0, value: sorting}
    ]};

    procStepResult = await procStepResult.proc.inputFields(1 , data);
    procStepResult = await procStepResult.proc.continueProc();
    console.log("new flow reults", procStepResult);
    console.log("url", procStepResult.Urls[0].url);

   /*  procStepResult = await procStepResult.proc.inputFields(1, {EditFields:[
      {field: 1, value: invoiceID, op: 0, value2: '', op2: ''},
      {field: 2, value: sorting, op: 0, value2: '', op2: ''}
    ]});  */
    /* let procStepResult = await priority.procStart('WWWSHOWTIV', 'P');    
    
    assert(procStepResult.type == 'inputFields');
    procStepResult = await procStepResult.proc.inputFields(1, {EditFields:[
      {field: 1, value: "RC239000002", op: 0, value2: '', op2: ''},
      {field: 2, value: 'לפי מספר הקבלה', op: 0, value2: '', op2: ''}
    ]}); */
 
      if (procStepResult.type == 'displayUrl') {
        setIsShowDoc(true);
        setPdfUrl(procStepResult.Urls[0].url);
      }
      /* Finish the proc */
      procStepResult = await procStepResult.proc.continueProc();
      assert(procStepResult.type == 'end');
    } catch (err) {
      console.log('Something awful happened:', err);
      console.dir(err);
      setIsShowDoc(true);
      errorMessage += err.code + " " + err.message
      setPdfUrl(errorMessage);
    }
  }
  useEffect(() => {
    
      showAIV();
    
  },[]);

  return (
    <>
      {!isShowDoc && (<div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
         {/* <p style={{ cursor: "pointer" }} onClick={() => webSDK("master", "AdiO2024+")}>
            Login.
          </p>
            <p style={{ cursor: "pointer" }} onClick={() => showAIV('IN224000004')}>
          Show doc
        </p> */}
        </header>
      </div>)}
      {isShowDoc && (<div className="App">
        <div id="pdf-link">{pdfUrl}</div>
      </div>)}
    </>

  );
}

export default AppOrder;
